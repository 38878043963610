<template>
  <div>
    <b-custom-spinner :is_progress="is_progress"></b-custom-spinner>

    <b-row>
      <b-col>
        <b-card no-body>
          <b-card-header class="d-block">
            <b-card-title>
              {{ lang("t_finishCodes") }}
            </b-card-title>
          </b-card-header>

          <b-card-body class="px-0">
            <b-card-text class="d-flex mx-2">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>

                <b-form-input
                  v-model="searchQuery"
                  :placeholder="lang('t_search')"
                ></b-form-input>
              </b-input-group>

              <b-button
                @click="
                  modal_record_details = true;
                  isEdit = false;
                  reset_record();
                "
                size="sm"
                class="px-50 py-50 ml-2"
                variant="flat-secondary"
              >
                <b-icon icon="plus"></b-icon>
              </b-button>
              <b-button size="sm" class="px-50 py-50" variant="flat-secondary">
                <b-icon icon="funnel"></b-icon>
              </b-button>
              <b-dropdown
                dropleft
                boundary="viewport"
                size="sm"
                variant="link"
                no-caret
                :right="$store.state.appConfig.isRTL"
              >
                <template #button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    class="align-middle text-body"
                  />
                </template>
                <b-dropdown-item @click="onexport">
                  <b-icon icon="download"></b-icon>
                  <span class="align-middle ml-50">{{ lang("t_export") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </b-card-text>
            <b-table
              :filter="searchQuery"
              small
              hover
              :fields="tableColumns"
              :items="finish_codes"
              responsive
              show-empty
              :empty-text="lang('t_noRecord')"
              :busy="finish_codes.length == 0"
              :per-page="perPage"
              :current-page="currentPage"
            >
              <template #empty="scope">
                <b-container class="mt-5">
                  <b-row>
                    <b-col class="text-center text-muted mt-50">
                      <span>{{ scope.emptyText }}</span>
                    </b-col>
                  </b-row>
                </b-container>
              </template>
              <template #cell(type)="data">
                <b-badge variant="light-info" v-if="data.value == 'call'">{{
                  lang("t_callFinishCode")
                }}</b-badge>
                <b-badge
                  variant="light-primary"
                  v-else-if="data.value == 'product'"
                  >{{ lang("t_productFinishCode") }}</b-badge
                >
                <b-badge
                  variant="light-success"
                  v-else-if="data.value == 'written_channel'"
                  >{{ lang("t_writtenChannelFinishCode") }}</b-badge
                >
                <b-badge variant="light-info" v-else>{{
                  lang("t_callFinishCode")
                }}</b-badge>
              </template>
              <template #cell(category_id)="data">
                {{
                  finish_categories.find((e) => e._id == data.item.category_id)
                    .category
                }}
              </template>
              <template #cell(require_date)="data">
                <feather-icon
                  :icon="data.item.require_date ? 'CheckIcon' : 'XIcon'"
                  size="12"
                  class="align-middle text-body"
                />
              </template>
              <template #cell(save_calendar)="data">
                <feather-icon
                  :icon="data.item.save_calendar ? 'CheckIcon' : 'XIcon'"
                  size="12"
                  class="align-middle text-body"
                />
              </template>
              <template #cell(is_last_finish_code)="data">
                <feather-icon
                  :icon="data.item.is_last_finish_code ? 'CheckIcon' : 'XIcon'"
                  size="12"
                  class="align-middle text-body"
                />
              </template>
              <template #cell(is_pnd)="data">
                <feather-icon
                  :icon="data.value ? 'CheckIcon' : 'XIcon'"
                  size="12"
                  class="align-middle text-body"
                />
              </template>
              <template #cell(is_assign)="data">
                <feather-icon
                  :icon="data.value ? 'CheckIcon' : 'XIcon'"
                  size="12"
                  class="align-middle text-body"
                />
              </template>
              <template #cell(is_auto_sms)="data">
                <feather-icon
                  :icon="data.value ? 'CheckIcon' : 'XIcon'"
                  size="12"
                  class="align-middle text-body"
                />
              </template>
              <template #cell(is_auto_email)="data">
                <feather-icon
                  :icon="data.value ? 'CheckIcon' : 'XIcon'"
                  size="12"
                  class="align-middle text-body"
                />
              </template>
              <template #cell(is_unassign)="data">
                <feather-icon
                  :icon="data.value ? 'CheckIcon' : 'XIcon'"
                  size="12"
                  class="align-middle text-body"
                />
              </template>
              <template #cell(is_passive)="data">
                <feather-icon
                  :icon="data.value ? 'CheckIcon' : 'XIcon'"
                  size="12"
                  class="align-middle text-body"
                />
              </template>

              <!-- Column: Actions -->
              <template #cell(actions)="data">
                <b-dropdown
                  dropleft
                  boundary="viewport"
                  :disabled="data.item.is_system ? true : false"
                  variant="link"
                  no-caret
                  :right="$store.state.appConfig.isRTL"
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="16"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click="showUsedAreas(data.item)">
                    <feather-icon icon="EyeIcon" />
                    <span class="align-middle ml-50"
                      >{{ lang("t_showUsedAreas") }}
                    </span>
                  </b-dropdown-item>
                  <b-dropdown-item @click="updateSelected(data.item)">
                    <feather-icon icon="EditIcon" />
                    <span class="align-middle ml-50">{{ lang("t_edit") }}</span>
                  </b-dropdown-item>

                  <b-dropdown-item @click="confirmText(data.item)">
                    <feather-icon icon="TrashIcon" />
                    <span class="align-middle ml-50">{{
                      lang("t_delete")
                    }}</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </b-card-body>

          <b-card-footer class="py-75 border-0">
            <div
              class="d-flex justify-content-between d-flex align-items-center"
            >
              <div class="d-flex align-items-center mb-0">
                <!-- <span class="text-nowrap"> Showing 1 to </span> -->
                <b-form-select
                  size="sm"
                  v-model="perPage"
                  :options="['10', '25', '50']"
                  class="mr-1"
                  @input="
                    (value) => props.perPageChanged({ currentPerPage: value })
                  "
                />
                <span class="text-nowrap text-muted font-small-3">
                  {{
                    lang("t_total") +
                    " " +
                    finish_codes.length +
                    " " +
                    lang("t_row").toLowerCase()
                  }}
                </span>
              </div>

              <b-pagination
                size="sm"
                class="mb-0"
                v-model="currentPage"
                :per-page="perPage"
                align="right"
                :total-rows="finish_codes.length"
                first-number
                last-number
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="16" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="16" />
                </template>
              </b-pagination>
            </div>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>

    <b-modal
      no-close-on-esc
      no-close-on-backdrop
      size="lg"
      :title="isEdit ? lang('t_edit') : lang('t_add')"
      centered
      v-model="modal_record_details"
      :ok-title="lang('t_save')"
      :cancel-title="lang('t_cancel')"
      @ok="add_records"
    >
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >
          <!-- finishCodeCategory -->
          <validation-provider
            #default="validationContext"
            name="finishCodeCategory"
            rules="required"
          >
            <b-form-group
              :label="lang('t_finishCodeCategory')"
              label-for="finishCodeCategory"
              :state="getValidationState(validationContext)"
            >
              <v-select
                id="finishCodeCategory"
                v-model="currentData.category_id"
                :options="finish_categories"
                :placeholder="lang('t_finishCodeCategory')"
                :reduce="(val) => val._id"
                label="category"
              ></v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <validation-provider
            #default="validationContext"
            name="finishCodeType"
            rules="required"
          >
            <b-form-group
              :label="lang('t_finishCodeType')"
              label-for="finishCodeCategory"
              :state="getValidationState(validationContext)"
            >
              <v-select
                id="finishCodeCategory"
                v-model="currentData.type"
                :options="types"
                :placeholder="lang('t_finishCodeType')"
                :reduce="(val) => val.internal_name"
                label="display_name"
                @input="onTypeChanged"
                :clearable="false"
              ></v-select>

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- finishCodeName -->
          <validation-provider
            #default="validationContext"
            name="finishCodeName"
            rules="required"
          >
            <b-form-group
              :label="lang('t_finishCodeName')"
              label-for="finishCodeName"
            >
              <b-form-input
                id="finishCodeName"
                v-model="currentData.finish_code"
                :state="getValidationState(validationContext)"
                trim
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-row class="mt-1">
            <b-col cols="4">
              <b-form-checkbox
                @input="currentData.save_calendar = false"
                v-model="currentData.require_date"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <!-- <i class="vs-icon feather icon-check" /> -->
                    {{ lang("t_askForActionDate") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="4">
              <b-form-checkbox
                :disabled="!currentData.require_date"
                v-model="currentData.save_calendar"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <!-- <i class="vs-icon feather icon-check" /> -->
                    {{ lang("t_saveToCalendar") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="4" v-if="currentData.type == 'call'">
              <b-form-checkbox
                v-model="currentData.is_last_finish_code"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <!-- <i class="vs-icon feather icon-check" /> -->
                    {{ lang("t_lastFinishCode") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col cols="4" v-if="currentData.type == 'call'">
              <b-form-checkbox
                v-model="currentData.is_contact_callable"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <!-- <i class="vs-icon feather icon-check" /> -->
                    {{ lang("t_contactCallableAfterwards") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="4" v-if="currentData.type == 'call'">
              <b-form-checkbox
                v-model="currentData.is_phone_callable"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <!-- <i class="vs-icon feather icon-check" /> -->
                    {{ lang("t_contactNumberCallableAfterwards") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="4" v-if="currentData.type == 'call'">
              <b-form-checkbox
                v-model="currentData.is_phone_attempts"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <!-- <i class="vs-icon feather icon-check" /> -->
                    {{ lang("t_countAsCallAttempt") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col cols="4" v-if="currentData.type == 'call'">
              <b-form-checkbox
                v-model="currentData.is_pnd"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <!-- <i class="vs-icon feather icon-check" /> -->
                    {{ lang("t_deleteContactNumber") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="4" v-if="currentData.type == 'call'">
              <b-form-checkbox
                @input="checkAssign(true)"
                v-model="currentData.is_assign"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <!-- <i class="vs-icon feather icon-check" /> -->
                    {{ lang("t_assignToAgent") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="4" v-if="currentData.type == 'call'">
              <b-form-checkbox
                v-model="currentData.show_in_dashboard"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <!-- <i class="vs-icon feather icon-check" /> -->
                    {{ lang("t_showInDashboard") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>

          <b-row class="mt-1">
            <b-col cols="4" v-if="currentData.type == 'call'">
              <b-form-checkbox
                v-model="currentData.is_auto_sms"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <!-- <i class="vs-icon feather icon-check" /> -->
                    {{ lang("t_isAutoSMS") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="4" v-if="currentData.type == 'call'">
              <b-form-checkbox
                @input="checkAssign(false)"
                v-model="currentData.is_unassign"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <!-- <i class="vs-icon feather icon-check" /> -->
                    {{ lang("t_isUnassign") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="4" v-if="currentData.type == 'call'">
              <b-form-checkbox
                v-model="currentData.is_passive"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <!-- <i class="vs-icon feather icon-check" /> -->
                    {{ lang("t_isPassive") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="4" v-if="currentData.type == 'call'">
              <b-form-checkbox
                v-model="currentData.is_auto_email"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <!-- <i class="vs-icon feather icon-check" /> -->
                    {{ lang("t_isAutoEmail") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="4" v-if="currentData.type == 'call'">
              <b-form-checkbox
                v-model="currentData.is_reached"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <!-- <i class="vs-icon feather icon-check" /> -->
                    {{ lang("t_isReached") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="4" v-if="currentData.type == 'call'">
              <b-form-checkbox
                v-model="currentData.is_extra_time"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    <!-- <i class="vs-icon feather icon-check" /> -->
                    {{ lang("t_giveExtraTime") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="4">
              <b-form-checkbox
                v-model="currentData.send_http_request"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    {{ lang("t_sendHttpRequest") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="4">
              <b-form-checkbox
                v-if="currentData.is_reached"
                v-model="currentData.is_owner"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    {{ lang("t_isOwner") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
            <b-col cols="4">
              <b-form-checkbox
                :disabled="currentData.require_date"
                v-model="currentData.auto_recall"
                class="custom-control-primary"
              >
                <span class="vs-checkbox">
                  <span class="vs-checkbox--check">
                    {{ lang("t_autoRecall") }}
                  </span>
                </span>
              </b-form-checkbox>
            </b-col>
          </b-row>
          <b-row class="mt-1" v-if="currentData.is_auto_sms">
            <b-col>
              <b-form-group
                :label="lang('t_smsTemplate')"
                label-for="t_smsTemplate"
              >
                <v-select
                  v-model="currentData.sms_template"
                  :options="sms_templates"
                  label="display_name"
                >
                </v-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group :label="lang('t_operator')" label-for="t_operator">
                <v-select
                  v-model="currentData.sms_settings"
                  :options="sms_settings"
                  label="display_name"
                >
                </v-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="lang('t_smsDelayAmount')"
                label-for="t_smsDelayAmount"
              >
                <b-form-input
                  v-model="currentData.sms_delay_amount"
                  trim
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-1" v-if="currentData.is_auto_email">
            <b-col>
              <b-form-group
                :label="lang('t_smsTemplate')"
                label-for="smsTemplate"
              >
                <v-select
                  v-model="currentData.email_template"
                  :options="email_templates"
                  label="display_name"
                >
                </v-select>
              </b-form-group>
            </b-col>

            <b-col>
              <b-form-group :label="lang('t_account')" label-for="t_account">
                <v-select
                  v-model="currentData.email_settings"
                  :options="email_settings"
                  label="display_name"
                >
                </v-select>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                :label="lang('t_emailDelayAmount')"
                label-for="t_emailDelayAmount"
              >
                <b-form-input
                  v-model="currentData.email_delay_amount"
                  trim
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-1">
            <b-col cols="4" v-if="currentData.is_extra_time">
              <b-form-group
                :label="lang('t_time')"
                label-for="extra_time_amount"
              >
                <b-form-input
                  v-model="currentData.extra_time_amount"
                  trim
                  type="number"
                />
              </b-form-group>
            </b-col>
            <b-col cols="4" v-if="currentData.is_extra_time">
              <b-form-group
                :label="lang('t_status')"
                label-for="extra_time_amount"
              >
                <v-select
                  v-model="currentData.extra_time_status"
                  :options="statuses.filter((e) => e.is_system != 1)"
                  label="display_name"
                  :reduce="(val) => val._id"
                  value="_id"
                >
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="4" v-if="currentData.auto_recall">
              <b-form-group
                :label="lang('t_autoRecallDay')"
                label-for="auto_recall_day"
              >
                <b-form-input
                  v-model="currentData.auto_recall_day"
                  trim
                  type="number"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-1" v-if="currentData.send_http_request">
            <b-col cols="6">
              <b-form-group :label="lang('t_type')">
                <v-select
                  v-model="currentData.request_info.type"
                  :options="['GET', 'POST', 'PUT', 'DELETE']"
                  :placeholder="lang('t_type')"
                >
                </v-select>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group :label="lang('t_url')">
                <b-form-input
                  v-model="currentData.request_info.url"
                  trim
                  :placeholder="lang('t_url')"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :label="lang('t_headers')">
                <b-form-textarea
                  rows="3"
                  v-model="currentData.request_info.headers"
                  trim
                  :placeholder="lang('t_headers')"
                />
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group :label="lang('t_body')">
                <b-form-textarea
                  rows="4"
                  v-model="currentData.request_info.body"
                  trim
                  :placeholder="lang('t_body')"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormCheckbox,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BModal,
  BCardFooter,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BFormSelect,
  BInputGroupPrepend,
  BInputGroup,
  BCardText,
  BContainer,
  BFormTextarea,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import useList from "./useList";
import storeModule from "../storeModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email, password } from "@validations";
import formValidation from "@core/comp-functions/forms/form-validation";

import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    // UsersListFilters,
    BFormTextarea,
    BContainer,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormCheckbox,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardFooter,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BFormSelect,
    BInputGroupPrepend,
    BInputGroup,
    BCardText,
    vSelect,
  },
  computed: {
    items() {
      const start = (this.currentPage - 1) * this.perPage;
      const end = this.currentPage * this.perPage;
      return this.finish_codes.slice(start, end);
    },

  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      is_progress: false,
      isEdit: false,
      finish_categories: [],
      statuses: [],
      finish_codes: [],
      sms_templates: [],
      sms_settings: [],
      selected_code: {},
      selected_category: {},
      types: [
        { internal_name: 'call', display_name: globalThis._lang('t_callFinishCode') },
        { internal_name: 'product', display_name: globalThis._lang('t_productFinishCode') },
        { internal_name: 'written_channel', display_name: globalThis._lang('t_writtenChannelFinishCode') },
      ],
      currentData: {
        type: "call",
        finish_code: "",
        category_id: "",
        require_date: false,
        require_note: false,
        save_calendar: false,
        is_phone_attempts: true,
        is_contact_callable: true,
        is_phone_callable: true,
        is_pnd: false,
        is_assign: false,
        is_extra_time: false,
        extra_time_amount: "0",
        extra_time_status: "",
        is_last_finish_code: false,
        show_in_dashboard: false,
        is_product_finish_code: false,
        is_chat_finish_code: false,
        is_reached: true,
        is_owner: false,
        is_auto_sms: false,
        sms_template: {},
        sms_settings: {},
        sms_delay_amount: 0,
        is_auto_email: false,
        email_template: {},
        email_settings: {},
        email_delay_amount: 0,
        is_unassign: false,
        is_passive: false,
        send_http_request: false,
        auto_recall: false,
        auto_recall_day: 1,
        request_info: {
          type: 'GET',
          url: '',
          headers: `{\n}`,
          body: `{\n}`,
        }
      },
      modal_record_details: false,
      required,
      alphaNum,
      email,
      password,
      tableColumns: [
        {
          key: "category_id",
          label: globalThis._lang("t_finishCodeCategory"),
          sortable: true,
        },
        {
          key: "finish_code",
          label: globalThis._lang("t_finishCodeName"),
          sortable: true,
        },
        {
          key: "type",
          label: globalThis._lang("t_type"),
          sortable: true,
        },
        {
          key: "require_date",
          label: globalThis._lang("t_askForActionDate"),
          sortable: true,
        },
        {
          key: "save_calendar",
          label: globalThis._lang("t_saveToCalendar"),
          sortable: true,
        },
        {
          key: "is_last_finish_code",
          label: globalThis._lang("t_lastFinishCode"),
          sortable: true,
        },
        {
          key: "is_pnd",
          label: globalThis._lang("t_deleteContactNumber"),
          sortable: true,
        },
        {
          key: "is_assign",
          label: globalThis._lang("t_assignToAgent"),
          sortable: true,
        },
        {
          key: "is_auto_sms",
          label: globalThis._lang("t_isAutoSMS"),
          sortable: true,
        },
        {
          key: "is_auto_email",
          label: globalThis._lang("t_isAutoEmail"),
          sortable: true,
        },
        {
          key: "is_unassign",
          label: globalThis._lang("t_isUnassign"),
          sortable: true,
        },
        {
          key: "is_passive",
          label: globalThis._lang("t_isPassive"),
          sortable: true,
        },
        {
          key: "actions",
          label: '',
          sortable: false,
          thStyle: { width: '5vh' }
        },
      ],
    };
  },
  mounted: function () {
    this.get_records();
    this.get_statuses();
    this.GetSmsTemplate();
    this.GetSmsSettings();
    this.GetEMailTemplate();
    this.GetEMailSettings();
  },

  methods: {
    async onexport() {
      try {
        this.is_progress = true;
        let _headers = {};
        for (const header of this.tableColumns) {
          _headers[header.key] = header.label;
        }

        let _data = [];
        for (const row of this.finish_codes) {
          let obj = {};
          var response = (await this.$http_in.post(`optmgr/v1/FinishCodeAreas`, {
            data: row
          })).data;
          console.log("data", response);
          if (response) {
            row["used_areas"] = response.join(',');
          }

          for (const column of Object.keys(row)) {
            let key = globalThis._lang(column) == undefined ? column : globalThis._lang(column);
            obj[key] = row[column];
          }
          _data.push(obj);
        }

        var data = this.$xlsx.utils.json_to_sheet(_data);

        var wb = this.$xlsx.utils.book_new();

        this.$xlsx.utils.book_append_sheet(wb, data, `finish_codes`);

        this.$xlsx.writeFile(wb, `finish_codes_${new Date().getTime()}.xlsx`);
      } catch (error) {
        console.log(error);
      } finally {
        this.is_progress = false;

      }

    },

    async showUsedAreas(item) {
      var response = (await this.$http_in.post(`optmgr/v1/FinishCodeAreas`, {
        data: item
      })).data;
      this.$swal({
        title: globalThis._lang('t_showUsedAreas'),
        width: 800,
        html: '<pre style="text-align:left">' + JSON.stringify(response, ' ', 2) + '</pre>',
        icon: "info",
        customClass: {
          confirmButton: "btn btn-primary",
        },
        buttonsStyling: false,
      });
    },
    onTypeChanged() {
      if (this.currentData.type == 'call') {
        this.currentData.is_product_finish_code = false;
        this.currentData.is_chat_finish_code = false;
      }
      if (this.currentData.type == 'product') {
        this.currentData.is_product_finish_code = true;
        this.currentData.is_chat_finish_code = false;
      }
      if (this.currentData.type == 'written_channel') {
        this.currentData.is_product_finish_code = false;
        this.currentData.is_chat_finish_code = true;
      }
    },
    checkAssign(type) {
      console.log(type);
      if (type) {
        if (this.currentData.is_assign) {
          this.currentData.is_unassign = false;
        }
      } else {
        if (this.currentData.is_unassign) {
          this.currentData.is_assign = false;
        }
      }
    },
    set_finish_code_type(type) {
      if (type == 'chat' && this.currentData.is_chat_finish_code) {
        this.currentData.is_product_finish_code = false;
      }
      if (type == 'product' && this.currentData.is_product_finish_code) {
        this.currentData.is_chat_finish_code = false;
      }
    },
    GetEMailTemplate: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/EMailTemplate`)).data;
      this.email_templates = response;
    },
    GetEMailSettings: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/EMailSettings`)).data;
      this.email_settings = response;
    },
    GetSmsTemplate: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/SmsTemplate`)).data;
      this.sms_templates = response;
    },
    GetSmsSettings: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/SmsSettings`)).data;
      this.sms_settings = response;
    },

    get_statuses: async function () {
      var response = (await this.$http_in.get(`/optmgr/v1/Status`)).data;
      console.log("statuses", response);
      this.statuses = response.status;
    },
    get_records: async function () {
      this.is_progress = true;

      var response = (await this.$http_in.get(`/optmgr/v1/FinishCodes`)).data;
      console.log("FinishCodes", response);
      this.finish_codes = response.finish_codes.filter((e) => !e.is_system);
      this.finish_categories = response.finish_categories;

      this.is_progress = false;
    },
    check_data() {
      if (
        this.currentData.finish_code == "" ||
        this.currentData.category_id == ""
      ) {
        return true;
      }
      return false;
    },
    add_records: async function () {
      if (this.check_data()) {
        this.$swal({
          title: globalThis._lang("t_warning"),
          text: globalThis._lang("t_pleaseDoNotLeaveAnyBlankFields"),
          icon: "warning",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
        return;
      }

      this.is_progress = true;
      var response;
      if (!this.isEdit) {
        if (
          this.finish_codes.find(
            (e) => e.finish_code == this.currentData.finish_code
          ) != undefined
        ) {
          this.$swal({
            title: globalThis._lang("t_error"),
            text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
          return;
        }

        response = (
          await this.$http_in.post(`/optmgr/v1/FinishCode`, {
            FinishCodeInfo: this.currentData,
          })
        ).data;
      } else {
        response = (
          await this.$http_in.put(`/optmgr/v1/FinishCode`, {
            FinishCodeInfo: this.currentData,
          })
        ).data;
      }
      this.is_progress = false;
      if (response && response.acknowledged) {
        this.$swal({
          title: globalThis._lang("t_processSuccess"),
          text: globalThis._lang("t_hasBeenCreatedSuccessfully"),
          icon: "success",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      } else {
        this.$swal({
          title: globalThis._lang("t_error"),
          text: globalThis._lang("t_AProblemOccurredwhileProcessing"),
          icon: "error",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          buttonsStyling: false,
        });
      }

      this.reset_record();
      this.get_records();
    },
    delete_record: async function () {
      try {
        this.is_progress = true;
        var response = (
          await this.$http_in.delete(
            `/optmgr/v1/FinishCode/${this.currentData._id}`,
            {
              headers: {
                authorization: globalThis.token,
              },
            }
          )
        ).data;

        if (response != null || response != undefined) {
          this.$swal({
            icon: "success",
            title: this.lang("t_processSuccess"),
            text: this.lang("t_customersHaveBeenSuccessfullyDeleted"),
            confirmButtonText: this.lang("t_ok"),
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      }
      catch (error) {
        if (error && error.response && error.response.data) {
          this.$swal({
            title: globalThis._lang("t_error"),
            text: `${error.response.data} kullanıldığı için kayıt silinemiyor.`,
            icon: "error",
            customClass: {
              confirmButton: "btn btn-primary",
            },
            buttonsStyling: false,
          });
        }
      } finally {
        this.is_progress = false;
      }

      this.reset_record();
      this.get_records();
    },
    reset_record() {
      this.isEdit = false;
      this.code_edit = false;
      this.selected_code = {};
      this.currentData = {
        _id: "",
        type: "call",
        finish_code: "",
        category_id: "",
        require_date: false,
        require_note: false,
        save_calendar: false,
        is_phone_attempts: true,
        is_contact_callable: true,
        is_phone_callable: true,
        is_pnd: false,
        is_assign: false,
        is_extra_time: false,
        extra_time_amount: "0",
        extra_time_status: "",
        is_last_finish_code: false,
        show_in_dashboard: false,
        is_product_finish_code: false,
        is_chat_finish_code: false,
        is_reached: true,
        is_owner: false,
        is_auto_sms: false,
        sms_template: {},
        sms_settings: {},
        sms_delay_amount: 0,
        is_auto_email: false,
        email_template: {},
        email_settings: {},
        email_delay_amount: 0,
        is_unassign: false,
        is_passive: false,
        send_http_request: false,
        auto_recall: false,
        auto_recall_day: 1,
        request_info: {
          type: 'GET',
          url: '',
          headers: `{\n}`,
          body: `{\n}`,
        }
      };
    },

    updateSelected(item) {
      this.selected_code = item;
      this.currentData = {
        ...item,
      };
      this.isEdit = true;
      this.modal_record_details = true;
      //this.isAddNewUserSidebarActive = true;
    },
    confirmText(item) {
      this.currentData = item;
      this.$swal({
        title: this.lang("t_deleteRecord"),
        text: this.lang("t_areYouSureYouWantToDeleteTheSelectedRecord"),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.lang("t_yes"),
        cancelButtonText: this.lang("t_cancel"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.delete_record();
        }
      });
    },
    deleteRecord: async function () {
      // Vue.$toast('Kayıt silme.');

      this.fetchData();
    },
  },
  setup() {
    const USER_APP_STORE_MODULE_NAME = "app-user";
    // const USER_APP_STORE_MODULE_NAME = 'app-user'
    const toast = Vue.$toast;

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME))
      store.registerModule(USER_APP_STORE_MODULE_NAME, storeModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME))
        store.unregisterModule(USER_APP_STORE_MODULE_NAME);
    });

    const blankUserData = {
      fullName: "",
      username: "",
      email: "",
      role: null,
      currentPlan: null,
      company: "",
      contact: "",
    };

    const resetuserData = () => {
      currentData.value = JSON.parse(JSON.stringify(blankUserData));
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData);

    const isAddNewUserSidebarActive = ref(false);

    const categoryOptions = [
      { label: "Kat1", value: "1" },
      { label: "Kat2", value: "2" },
      { label: "Kat3", value: "3" },
      { label: "Kat4", value: "4" },
    ];
    const extensOptions = [
      { label: "123", value: "123" },
      { label: "456", value: "456" },
      { label: "9999", value: "9999" },
      { label: "654", value: "654" },
    ];
    const projectOptions = [
      { label: "Abc", value: "1" },
      { label: "Proje2", value: "2" },
      { label: "Proje3", value: "3" },
      { label: "Proje4", value: "4" },
    ];
    const profileOptions = [
      { label: "Admin", value: "1" },
      { label: "Profil2", value: "2" },
      { label: "Profil3", value: "3" },
      { label: "Profil4", value: "4" },
    ];

    const roleOptions = [
      { label: "Admin", value: "admin" },
      { label: "Author", value: "author" },
      { label: "Editor", value: "editor" },
      { label: "Maintainer", value: "maintainer" },
      { label: "Subscriber", value: "subscriber" },
    ];

    const planOptions = [
      { label: "Basic", value: "basic" },
      { label: "Company", value: "company" },
      { label: "Enterprise", value: "enterprise" },
      { label: "Team", value: "team" },
    ];

    const statusOptions = [
      { label: "Pending", value: "pending" },
      { label: "Active", value: "active" },
      { label: "Inactive", value: "inactive" },
    ];

    var {
      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,
    } = useList();

    return {
      // Sidebar
      isAddNewUserSidebarActive,

      fetchData,
      tableColumns,
      perPage,
      currentPage,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      categoryOptions,
      extensOptions,
      projectOptions,
      profileOptions,
      roleOptions,
      planOptions,
      statusOptions,

      // Extra Filters
      roleFilter,
      planFilter,
      statusFilter,

      toast,
      refFormObserver,
      getValidationState,
      resetForm,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
